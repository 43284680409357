.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sectionTitle {
  margin-bottom: 2px;
  margin-top: 16px;
  color: gray;
}

p {
  color: #2f2e2e;
}

.datePicker-button {
  font-size: x-large;
  /*margin-top: 16px;*/
  background-color: #ececec; /* Green */
  border: none;
  color: #2f2e2e;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  width: 100%;
}

#linehPowerconsumption{
  float: right;
  height: 10px;
  background: #D50055;
  animation: lineH 4s 0s infinite infinite;
}
#linehPowerproduction{
  float: left;
  height: 10px;
  background: green;
  animation: lineH 4s 0s infinite linear;
}

#linehPowerexport{
  float: left;
  height: 10px;
  background: green;
  animation: lineH 4s 0s infinite linear;
}

@keyframes lineH{
  0%{
    width: 0%;opacity: 0;
  }
  100%{
    width: 100%;opacity: 1;
  }
}


.menu {
  text-align: center;
}

.btn {
  width: 100px;
  height: 100px;
  margin: 16px;
}

input[type=file] {
  
}

.fileLoader {
  text-align: center;
}

.iconHeader {
  margin: 8px;
}

.red {
  background: red;
}

.blue {
  background: blue;
}